<template lang="html">
  <div class="">
    <div :style="{width:'90%', textAlign:'left',position:'absolute',marginLeft:'2%',marginTop:'0%',height:'66px'}">
      <img  @click="explore_page()" :src= "`${publicPath}`+ 'cygonia1.jpg'" :style="{height:'65px',textAlign:'left', cursor:'pointer',marginTop:'0.3%',marginLeft:'0%', width:'65px',borderRadius:'50%'}">
    </div>


    <div :style="{height:'100%',width:'100%',marginTop:'3%', display:'flex'}">
        <div :style="{ height:'100%',marginLeft:'16%', textAlign:'left'}">
          <div :style="{fontWeight:'bold', fontSize:'43px', height:'90px',color: '#453478'}">
            Create user to company
          </div>
          <div :style="{fontWeight:'bold', marginLeft:'6%',textAlign:'left',fontSize:'18px', height:'29px',color: '#453478'}">
            Choose company
          </div>
          <div class="input_box_input_class2">
            <input type="text" class="input_box_input" v-model="search_company"  placeholder="Company" v-on:keyup="load_company_func(search_company)" >
          </div>
          <div :style="{marginTop:'1%',border: '2px solid #59419B6F', borderRadius:'8px',marginLeft:'4%',width:'90%',maxHeight:'110px', overflowY:'auto'}">
            <div v-for="(company,idx) in company_list" :key="idx" :style="{marginLeft:'5%' ,textAlign:'left',  width:'90%',height:'18px', fontSize:'14px', marginTop:'0%'}">
              <div :style="{width:'100%',height:'20px', display:'flex',marginTop:'1%'}" @click="choose_company(company)">
                <div :style="{marginLeft:'1%',marginTop:'0.5%'}">
                  {{company.name}}
                </div>
              </div>
            </div>
          </div>
          <div :style="{marginLeft:'0%', height:'270px'}">
            <div class="input_box">
             <label class="input_box_label"  for="email">Email</label>
             <div class="input_box_input_class">
               <input @change='check_email_exists()' type="text"  class="input_box_input" placeholder="example@email.com" v-model.trim="email">
             </div>
             <label for="password" class="input_box_label">Password</label><br>
             <div class="input_box_input_class" >
               <input type="password" maxlength="500" placeholder="Password" class="input_box_input"     v-model.trim="password" >
             </div>
            </div>
          </div>
          <button
          @click="create_user()"
          :disabled=" this.email=='' && this.password=='' "
            :class="[ this.email != '' && this.password != ''  ? 'button_checked' : 'button_unchecked' ]"


          >
            Create user
          </button>
          <div :style="{height:'120px'}">

          </div>
        </div>
  </div>
  </div>
</template>

<script>
import axios from 'axios';
import {API_URL} from '../../config.js'

export default {
  data:function(){
    return{
      publicPath: process.env.BASE_URL +'',
      search_company:'',
      email:'',
      password:'',
      chosen_company:[],
      company_list:[]
    }
},
  methods:{
    load_company_func(search_item){
      var self = this
      axios({
          method:'get',
          url: API_URL+ '/get_companies'+'?search_item='+search_item,
        })
        .then(function(response){
            self.company_list = response.data
        })
    },
    create_user(){
      var self = this
      axios({
         method:'post',
         url: API_URL+ '/create_user',
         data:{"email":  this.email, "password":this.password, "chosen_company":this.chosen_company}
       })
       .then(function(){
         self.$alert(" User has been added ")
       })
    },
    choose_company(company){
      this.search_company = company.name
      this.chosen_company = [{"company_id":company.id, "company_name":company.name}]
    },

    check_email_exists(){

     var self= this;

     if (this.email != ''  ){

       axios({
          method:'get',
          url: API_URL+ '/register_check_email_username'+ '?email='+this.email,
        })
        .then(function(response){
          if (response.data =='denied'){
            self.$alert(" Email  already exists, please try again ")
            self.email=""
            }
          })
         }
         else{
              self.$alert("Either Email is empty or has the wrong format")
         }
    },

  },
  mounted(){
    this.load_company_func("")
  }
}
</script>

<style lang="css" scoped>
input:focus {outline:none!important;}
.input_box{
  width: 95%;
  height: 274px;
  margin-left: 5%;
  margin-top: -2%;
    color: #453478;
    text-align: left;
}

.input_box_label{
  font-size: 15px;
  height: 15px;
  width: 60px;
  font-weight: bold;
  color: #453478;
  margin-left: 0%;
  margin-top: 9%;
  text-align: left;
}

.input_box_input_class{
  width: 95%;
  height: 59px;
  border-radius: 12px;
  color: #453478;
  margin-top: 2%;
  border: 4px solid #59419B6F ;
}
.input_box_input_class2{
  width: 90%;
  height: 59px;
  margin-left: 4%;
  border-radius: 12px;
  color: #453478;
  margin-top: 2%;
  border: 4px solid #59419B6F ;
}

.input_box_input{
  width: 70%;
  height: 90%;
  border-radius: 12px;
  color: #453478;
  border-color: #453478;
  color: black;
  padding-left: 2%;
  border-style: none;
}
.account_class1{
  width: 608px;
  margin-left: 2%;
  height: 90px;
  margin-top: 2%;
  color: #453478;
  cursor:pointer;
}
.title{
  width: 95%;
  height: 50px;
  margin-top:2%;
  font-size:25px;
  margin-left:0%;
  color:#453478;
  line-height: normal;
}
.input_field{
  border: 2px solid #59419B6F ;
  color:black;
  margin-top:1%;
  width:58%;
  margin-left: 2%;
   border-radius: 8px;

   height:29px;

}

.button_checked{
  border-radius:12px;
  border-style:none;
  margin-left: 14%;
  font-Size:22px;
  height:56px;
  width:324px;
  margin-Top:2%;
  color:white;
  background-Color:#453478
}

.button_unchecked{
  border-radius:12px;
  border-style:none;
  margin-left: 14%;
  font-Size:22px;
  height:56px;
  width:324px;
  margin-Top:2%;
  color:white;
  background-Color:grey
}

.main_comp{
    margin-left: -5%;
    margin-top: 0%;
}

.search_ruta_input{
  margin-left: 0%;
  width: 88%;
  margin-top: 2%;
  border-radius: 8px;
  z-index: 4;
  font-size: 14px;
  border: 2px solid #59419B6F ;
  color:black;
  height:29px;

}
</style>
